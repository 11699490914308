<template>
  <div class="callback">Logging in...</div>
</template>

<script>
export default {
  name: 'AuthCallback',
 mounted () {
    this.$store.dispatch('auth/handleAuthentication').then(() => {
      console.log('redirecting to user account management after successful login')
      this.$router.replace('/user-account')
    }, (err) => {
      console.log('err...')
      console.log(err)
      // console.log('route params...')
      // console.log(this.$route.params)
      // TODO: pass the error object into the error page...
      this.$router.replace({
        name: 'auth-error',
        params: {
          error: err.error,
          errorDescription: err.errorDescription,
          errorObj: err
        }
      })
    })
  }
}
</script>

<style>

</style>
